import _defineProperty from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
import { provide, readonly } from 'vue';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn'; // defineComponent({
//   components: {
//     [ElConfigProvider.name]: ElConfigProvider,
//   },
//   data() {
//     return {
//       locale: zhCn,
//     }
//   },
// })

export default {
  components: _defineProperty({}, ElConfigProvider.name, ElConfigProvider),
  setup: function setup() {
    provide('globalTitle', readonly(process.env.VUE_APP_TITLE));
    provide('globalHost', readonly(process.env.VUE_APP_PROXY_HOST));
    provide('globalApi', readonly(process.env.VUE_APP_BASE_API));
    provide('globalAppCode', readonly(process.env.VUE_APP_CODE));
    var locale = zhCn;
    return {
      locale: locale
    };
  } // mounted() {
  //   localStorage.title = process.env.VUE_APP_TITLE
  // }

};