import _objectSpread from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _toConsumableArray from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { requestPost, requestGet } from '@/api/index';
import { setToken, getToken, setMenus, setUserId } from '@/utils/auth';
import router from '@/router';
import { constantRoutes } from '@/router/config';
import { asyncRoutes } from '@pro/asyncRoutes';
import { ElMessage } from 'element-plus';

var state = function state() {
  return {
    user: {
      menus: []
    },
    todos: [{
      id: 1,
      text: '...',
      done: true
    }, {
      id: 2,
      text: '...',
      done: false
    }]
  };
};

var mutations = {
  setUserInfo: function setUserInfo(_ref, payload) {
    var user = _ref.user;
    var _payload$id = payload.id,
        id = _payload$id === void 0 ? '' : _payload$id,
        _payload$name = payload.name,
        name = _payload$name === void 0 ? '' : _payload$name,
        _payload$phone = payload.phone,
        phone = _payload$phone === void 0 ? '' : _payload$phone,
        _payload$token = payload.token,
        token = _payload$token === void 0 ? '' : _payload$token,
        _payload$avatar = payload.avatar,
        avatar = _payload$avatar === void 0 ? '' : _payload$avatar,
        _payload$roleId = payload.roleId,
        roleId = _payload$roleId === void 0 ? '' : _payload$roleId,
        _payload$intro = payload.intro,
        intro = _payload$intro === void 0 ? '' : _payload$intro,
        _payload$hid = payload.hid,
        hid = _payload$hid === void 0 ? '' : _payload$hid,
        _payload$hpid = payload.hpid,
        hpid = _payload$hpid === void 0 ? '' : _payload$hpid;
    user.id = id; // setUserId(id)

    user.token = token;
    setToken(token);
    user.name = name;
    user.phone = phone;
    user.avatar = avatar;
    user.roles = Array.isArray(roleId) ? roleId : [roleId];
    user.intro = intro;
    user.hid = hid;
    user.hpid = hpid;
  },
  setMenuItems: function setMenuItems(_ref2, items) {
    var user = _ref2.user;
    user.menus = items;
  }
};
var actions = {
  getMenusPhp: function getMenusPhp(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve) {
      var arrangedMenus = getMenuItems([], 1);
      var menus = [].concat(_toConsumableArray(constantRoutes), _toConsumableArray(arrangedMenus)); // console.log(menus, 'menus');

      commit('setMenuItems', menus);

      var _iterator = _createForOfIteratorHelper(menus),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          router.addRoute(item);
        } // console.log('state -- ', state, router.getRoutes())

      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      resolve({
        token: getToken(),
        menus: menus
      });
    });
  },
  getInfo: function getInfo(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      requestPost('/staff/info', {
        token: getToken()
      }).then(function (_ref5) {
        var data = _ref5.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        var staffId = data.staffId,
            token = data.token,
            roleId = data.roleId,
            _data$roleScope = data.roleScope,
            roleScope = _data$roleScope === void 0 ? 0 : _data$roleScope,
            introduction = data.introduction,
            _data$name = data.name,
            name = _data$name === void 0 ? '' : _data$name,
            avatar = data.avatar,
            phone = data.phone,
            _data$bindHospitals = data.bindHospitals,
            bindHospitals = _data$bindHospitals === void 0 ? [] : _data$bindHospitals;

        if (!roleId) {
          reject('getInfo: roleId must be a String!');
        }

        localStorage.bindHospitals = JSON.stringify(bindHospitals);
        userName;
        localStorage.roleScope = roleScope || 2;
        localStorage.nickname = name;
        commit('setUserInfo', {
          id: staffId,
          name: name,
          token: token,
          avatar: avatar,
          phone: phone,
          roleId: roleId,
          roleScope: roleScope,
          intro: introduction
        });
        resolve({
          token: token,
          roleId: roleId
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // loginPhp({ commit }, params) {
  //   return new Promise((resolve, reject) => {
  //     requestPost('/api/login', params).then(({ data }) => {
  //       const {
  //         id,
  //         token,
  //         rid,
  //         name = '',
  //         phone,
  //         menus = []
  //       } = data
  //       localStorage.nickname = name
  //       const bizCode = process.env.VUE_APP_CODE
  //       commit('setUserInfo', {
  //         id,
  //         name,
  //         token,
  //         phone,
  //         roleId: rid
  //       })
  //       setToken(token)
  //       const arrangedMenus = getMenuItems(menus, 1)
  //       console.log('arrangedMenus', arrangedMenus)
  //       commit('setMenuItems', arrangedMenus)
  //       // setMenus(arrangedMenus)
  //       console.log('arrangedMenus - ', arrangedMenus, router)
  //       for (let item of arrangedMenus) {
  //         router.addRoute(item)
  //       }
  //       resolve()
  //     }).catch(error => {
  //       console.log('login error ', error)
  //       reject(error)
  //     })
  //   })
  // },
  login: function login(_ref6, params) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      requestPost('/login', params, params).then(function (_ref7) {
        var data = _ref7.data;
        var staffId = data.staffId,
            token = data.token,
            roleId = data.roleId,
            _data$roleScope2 = data.roleScope,
            roleScope = _data$roleScope2 === void 0 ? 0 : _data$roleScope2,
            introduction = data.introduction,
            _data$name2 = data.name,
            name = _data$name2 === void 0 ? '' : _data$name2,
            _data$userName = data.userName,
            userName = _data$userName === void 0 ? '' : _data$userName,
            avatar = data.avatar,
            phone = data.phone,
            _data$bindHospitals2 = data.bindHospitals,
            bindHospitals = _data$bindHospitals2 === void 0 ? [] : _data$bindHospitals2,
            _data$menus = data.menus,
            menus = _data$menus === void 0 ? [] : _data$menus;
        localStorage.nickname = name;
        localStorage.nickname = userName;
        var bizCode = process.env.VUE_APP_CODE;

        if (bizCode == 'paycenter') {
          commit('setUserInfo', {
            id: staffId,
            name: name,
            token: token,
            avatar: avatar,
            phone: phone,
            roleId: roleId,
            roleScope: roleScope,
            intro: introduction
          }); // const routes = getAllRoutes()
          // commit('SET_ROUTES', routes)
          // router.addRoutes(routes)
        } else {
          commit('setUserInfo', {
            id: staffId,
            name: name,
            token: token,
            avatar: avatar,
            phone: phone,
            roleId: roleId,
            roleScope: roleScope,
            intro: introduction
          }); // const routes = getRoutes(menus)
          // commit('SET_ROUTES', routes)
          // router.addRoutes(routes)
        }

        var arrangedMenus = getMenuItems(menus);
        commit('setMenuItems', arrangedMenus); // console.log('arrangedMenus - ', arrangedMenus, router)

        var _iterator2 = _createForOfIteratorHelper(arrangedMenus),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var item = _step2.value;
            router.addRoute(item);
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        resolve();
      }).catch(function (error) {
        console.log('login error ', error);
        reject(error);
      });
    });
  },
  // logoutPhp({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     commit('setUserInfo', {
  //       id: 0,
  //       name: '',
  //       token: '',
  //       avatar: '',
  //       phone: '',
  //       roleId: 0,
  //       roleScope: 0,
  //       intro: ''
  //     })
  //     commit('setMenuItems', [])
  //     localStorage.clear()
  //     router.replace('/login')
  //     resolve()
  //   })
  // },
  logout: function logout(_ref8) {
    var commit = _ref8.commit;
    return new Promise(function (resolve, reject) {
      commit('setUserInfo', {
        id: 0,
        name: '',
        token: '',
        avatar: '',
        phone: '',
        roleId: 0,
        roleScope: 0,
        intro: ''
      });
      commit('setMenuItems', []);
      localStorage.clear();
      ElMessage.success({
        message: '退出成功~',
        duration: 4000
      });
      router.replace('/login'); // requestPost('/login/logout', {
      // token: getToken()
      // })
      //   .finally(() => {
      //     router.replace('/login')
      //     resolve()
      //   })
    });
  }
};

function getMenuItems(menus) {
  var devFlag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var accessedRoutes = asyncRoutes || [];
  var menusMap = {};
  var routes = [];

  if (devFlag) {
    routes = accessedRoutes;
  } else {
    if (!menus || menus.length < 1) {
      console.log('getMenu：没有找到' + localStorage.bizCode + '的菜单');
      return [];
    }

    menusMap = menus.reduce(function (ret, menu) {
      ret[menu.menuPath] = menu;
      return ret;
    }, {});
    routes = filterMenus(accessedRoutes, menusMap);
  } // console.log('accessedRoutes - ', accessedRoutes)
  // console.log('menusMap - ', menusMap)


  routes.sort(function (a, b) {
    var _a$meta, _b$meta;

    return ((a === null || a === void 0 ? void 0 : (_a$meta = a.meta) === null || _a$meta === void 0 ? void 0 : _a$meta.rank) || 99) - ((b === null || b === void 0 ? void 0 : (_b$meta = b.meta) === null || _b$meta === void 0 ? void 0 : _b$meta.rank) || 99);
  });
  routes.push({
    path: '/404',
    redirect: '/404',
    hidden: true,
    meta: {
      title: '404'
    }
  });
  return routes;
}

function filterMenus(routes, menusMap) {
  var res = [];
  routes.forEach(function (route) {
    var spam = _objectSpread({}, route);

    var menuInfo = menusMap[spam.path]; // let menuInfo = { menuTitle: '', menuIcon: '', menuRank: 99 }

    if (menuInfo) {
      spam.meta.title = menuInfo.menuTitle || spam.meta.title;
      spam.meta.icon = menuInfo.menuIcon || spam.meta.icon;
      spam.meta.rank = menuInfo.menuRank || 9999;

      if (spam.children) {
        spam.children = filterMenus(spam.children, menusMap);
        spam.children.sort(function (a, b) {
          return a.meta.rank - b.meta.rank;
        });
      }

      res.push(spam);
    } else if (spam.hidden) {
      res.push(spam);
    }
  });
  return res;
}

var account = {
  state: state,
  mutations: mutations,
  actions: actions,
  namespaced: true,
  strict: false
};
export default account;