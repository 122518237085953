var LOCAL_KEYS = {
  TOKEN: 'ly-token',
  USER_ID: 'ly-uid',
  MENU: 'ly-uid'
};
export function getToken() {
  return localStorage.getItem(LOCAL_KEYS.TOKEN) || '';
}
export function setToken(val) {
  return localStorage.setItem(LOCAL_KEYS.TOKEN, val);
}
export function getMenus() {
  var val = localStorage.getItem(LOCAL_KEYS.MENU) || '';

  if (val && !Array.isArray(val)) {
    return JSON.parse(val);
  }

  return val;
}
export function setMenus(val) {
  if (Array.isArray(val)) {
    return localStorage.setItem(LOCAL_KEYS.MENU, JSON.stringify(val));
  } else {
    return localStorage.setItem(LOCAL_KEYS.MENU, val);
  }
}
export function removeToken() {
  localStorage.removeItem(LOCAL_KEYS.TOKEN);
}
export function getUserId() {
  return localStorage.getItem(LOCAL_KEYS.USER_ID) || '';
}
export function setUserId(val) {
  return localStorage.setItem(LOCAL_KEYS.USER_ID, val);
}
export function getChannelCode() {
  return 'admin-12320';
}