import script from "./Label.vue?vue&type=script&lang=js"
export * from "./Label.vue?vue&type=script&lang=js"

import "./Label.vue?vue&type=style&index=0&id=69647304&lang=scss&scoped=true"
script.__scopeId = "data-v-69647304"
/* hot reload */
if (module.hot) {
  script.__hmrId = "69647304"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('69647304', script)) {
    api.reload('69647304', script)
  }
  
}

script.__file = "src/layout/components/Sidebar/Label.vue"

export default script