import { render } from "./AppMain.vue?vue&type=template&id=078753dd&scoped=true"
import script from "./AppMain.vue?vue&type=script&lang=js"
export * from "./AppMain.vue?vue&type=script&lang=js"

import "./AppMain.vue?vue&type=style&index=0&id=078753dd&lang=scss&scoped=true"
import "./AppMain.vue?vue&type=style&index=1&id=078753dd&lang=scss"
script.render = render
script.__scopeId = "data-v-078753dd"
/* hot reload */
if (module.hot) {
  script.__hmrId = "078753dd"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('078753dd', script)) {
    api.reload('078753dd', script)
  }
  
  module.hot.accept("./AppMain.vue?vue&type=template&id=078753dd&scoped=true", () => {
    api.rerender('078753dd', render)
  })

}

script.__file = "src/layout/components/AppMain.vue"

export default script