import { render } from "./index.vue?vue&type=template&id=13877386&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=13877386&lang=scss"
import "./index.vue?vue&type=style&index=1&id=13877386&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-13877386"
/* hot reload */
if (module.hot) {
  script.__hmrId = "13877386"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('13877386', script)) {
    api.reload('13877386', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=13877386&scoped=true", () => {
    api.rerender('13877386', render)
  })

}

script.__file = "src/layout/index.vue"

export default script