import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Fragment, null, [_createCommentVNode(" eslint-disable vue/require-component-is "), (_openBlock(), _createBlock(_resolveDynamicComponent($setup.ctype), $setup.info, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
    /* FORWARDED */

  }, 16
  /* FULL_PROPS */
  ))], 2112
  /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
  );
}