import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  var _component_el_config_provider = _resolveComponent("el-config-provider");

  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: $setup.locale
  }, {
    default: _withCtx(function () {
      return [_createVNode("div", _hoisted_1, [_createVNode(_component_router_view)])];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["locale"]);
}