import _objectSpread from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { computed, readonly } from 'vue';
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem: SidebarItem
  },
  setup: function setup() {
    var cssVariables = computed(function () {
      return variables;
    });
    return {
      cssVariables: cssVariables,
      openFolders: readonly(['/admin', '/usermanage', '/studysmanage', '/meetingsmanage', '/invoicesmanage', '/speciality'])
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['menus', 'sidebar'])), {}, {
    // ...mapGetters(['sidebar']),
    activeMenu: function activeMenu() {
      // console.log('menus', this.menus)
      var _this$$route = this.$route,
          meta = _this$$route.meta,
          path = _this$$route.path; // console.log('meta--', meta, path)
      // if set path, the sidebar will highlight the path you set

      return meta.activeMenu || path || 'hospital';
    }
  })
};