import "/Users/boji/Documents/leyue/association-admin-vue/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/boji/Documents/leyue/association-admin-vue/node_modules/core-js/modules/es.promise.js";
import "/Users/boji/Documents/leyue/association-admin-vue/node_modules/core-js/modules/es.object.assign.js";
import "/Users/boji/Documents/leyue/association-admin-vue/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

/*
 * @Date: 2022-07-12 15:12:42
 * @LastEditors: kenan
 * @LastEditTime: 2022-07-14 15:52:42
 */
import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css'; // import locale from './utils/zh-cn'

import locale from 'element-plus/lib/locale/lang/zh-cn';
import emojiRegex from 'emoji-regex'; // npm install emoji-regex
// import '@/styles/index.scss'

import store from './store';
import router from "./router"; // import PageHandler from '@pro/mixins/PageHandler'

var app = createApp(App);
app.directive("clearEmoij", {
  mounted: function mounted(el, binding, vnode, oldVnode) {
    var regex = emojiRegex();
    var obj = el.querySelectorAll('.el-input__inner,.el-textarea__inner')[0];

    var zclearNoNum = function zclearNoNum(e) {
      if (e.target.composing) return;
      var match = regex.exec(obj.value); // 也可以直接用正则表达式判断

      if (match) {
        /* for (let i=0;i<match.length;i++) {
        	obj.value = obj.value.replace(match[i],"");
        }*/
        obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
        zclearNoNum(e);
      } // 触发v-model的更新


      obj.dispatchEvent(new Event('input'));
    };

    var zblur = function zblur(e) {
      zclearNoNum(e);
      setTimeout(function () {
        var match = regex.exec(obj.value);

        if (match) {
          obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
          zclearNoNum(e);
        } // 触发v-model的更新


        obj.dispatchEvent(new Event('change'));
      }, 50);
    };

    obj.onkeyup = zclearNoNum;
    obj.onblur = zblur;

    function onCompositionStart(e) {
      e.target.composing = true;
    }

    function onCompositionEnd(e) {
      console.log('按回车将字输入', e.target.value);
      e.target.composing = false;
      obj.dispatchEvent(new Event('change'));
      setTimeout(function () {
        if (obj.value) {
          obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
          zclearNoNum(e);
        } // 触发v-model的更新


        obj.dispatchEvent(new Event('change'));
      }, 50);
    }

    obj.addEventListener('compositionstart', onCompositionStart);
    obj.addEventListener('compositionend', onCompositionEnd);
  }
});
app.use(store).use(ElementPlus, {
  locale: locale
}).use(router) // .mixin(PageHandler)
.mount('#app');