import _typeof from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/typeof";
import _slicedToArray from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import { getToken } from '@/utils/auth';
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
export function isChineseName(str) {
  // strRegex = @"[\u4e00-\u9fa5]|[\（\）\《\》\——\；\，\。\“\”\<\>\！]";
  var reg = /^[\u0391-\uFFE5.·•]+$/;
  return str && reg.test(str);
}
export function isMobile(str) {
  var reg = /^1[1-9]\d{9}$/;
  return str && reg.test(str);
}
export function isPassword(str) {
  var reg = /^[A-Za-z0-9]{8,24}$/;
  return str && reg.test(str);
}
export function isEmojiCharacter(substring) {
  var reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;

  if (substring.match(reg)) {
    return true;
  }

  for (var i = 0; i < substring.length; i++) {
    var hs = substring.charCodeAt(i);

    if (0xd800 <= hs && hs <= 0xdbff) {
      if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1);
        var uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000;

        if (0x1d000 <= uc && uc <= 0x1f77f) {
          return true;
        }
      }
    } else if (substring.length > 1) {
      var _ls = substring.charCodeAt(i + 1);

      if (_ls == 0x20e3) {
        return true;
      }
    } else {
      if (0x2100 <= hs && hs <= 0x27ff) {
        return true;
      } else if (0x2B05 <= hs && hs <= 0x2b07) {
        return true;
      } else if (0x2934 <= hs && hs <= 0x2935) {
        return true;
      } else if (0x3297 <= hs && hs <= 0x3299) {
        return true;
      } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030 || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b || hs == 0x2b50) {
        return true;
      }
    }
  }

  return false;
}
export function enum2Map(enumObj) {
  var map = {};

  for (var key in enumObj) {
    map[enumObj[key].code] = enumObj[key].desc || enumObj[key].msg;
  }

  return map;
}
export function enum2MapStatus(enumObj) {
  var map = {};

  for (var key in enumObj) {
    map[enumObj[key].status] = enumObj[key].desc || enumObj[key].msg;
  }

  return map;
}
export function enum2MapType(enumObj) {
  var map = {};

  for (var key in enumObj) {
    map[enumObj[key].type] = enumObj[key].desc || enumObj[key].msg;
  }

  return map;
}
export function exportExcel(url, queryObject) {
  var query = Object.entries(queryObject).reduce(function (result, entry) {
    var _entry = _slicedToArray(entry, 2),
        key = _entry[0],
        val = _entry[1];

    if (key !== 'pageNum' && key != 'pageSize' && val != undefined) {
      result.push(entry.join('='));
    }

    return result;
  }, []).join('&');
  console.log(query);
  var link = document.createElement('a');
  link.target = '_blank';
  link.href = process.env.VUE_APP_BASE_API + url + '?' + query + '&token=' + getToken();
  link.click();
}
export function trimText(x) {
  if (!x) {
    return '';
  }

  return x.replace(/(^\s*)|(\s*$)/g, '');
} // 去除转义

export function escape2Html(str) {
  if (!str) {
    return '';
  }

  var arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp': '&',
    'quot': '"'
  };
  return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
    return arrEntities[t];
  });
}
export function getDaysBetween(start, end) {
  var startDate = Date.parse(start);
  var endDate = Date.parse(end);
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}
export function _dateAdd(interval) {
  var currentDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  var dt = new Date(currentDate.getTime() + 86400 * 1000 * interval);
  var year = dt.getFullYear();
  var month = dt.getMonth() + 1;
  var day = dt.getDate(); // let hour = dt.getHours()
  // let min = dt.getMinutes()
  // let sec = dt.getSeconds()

  return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day); // + ' ' +
  // (hour < 10 ? '0' + hour : hour) + ':' +
  // (min < 10 ? '0' + min : min) + ':' +
  // (sec < 10 ? '0' + sec : sec)
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

export function parseTime(time, cFormat) {
  if (!time || arguments.length === 0 || time.indexOf('0000-00') >= 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    return value.toString().padStart(2, '0');
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}