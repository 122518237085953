import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import { createStore } from 'vuex';
import account from './modules/account';
import app from './modules/app';
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  size: function size(state) {
    return state.app.size;
  },
  menus: function menus(state) {
    var _state$account$user;

    return ((_state$account$user = state.account.user) === null || _state$account$user === void 0 ? void 0 : _state$account$user.menus) || [];
  },
  token: function token(state) {
    var _state$account$user2;

    return ((_state$account$user2 = state.account.user) === null || _state$account$user2 === void 0 ? void 0 : _state$account$user2.token) || localStorage.token;
  },
  name: function name(state) {
    var _state$account$user3;

    return ((_state$account$user3 = state.account.user) === null || _state$account$user3 === void 0 ? void 0 : _state$account$user3.name) || '';
  },
  phone: function phone(state) {
    var _state$account$user4;

    return ((_state$account$user4 = state.account.user) === null || _state$account$user4 === void 0 ? void 0 : _state$account$user4.phone) || '';
  },
  avatar: function avatar(state) {
    var _state$account$user5;

    return ((_state$account$user5 = state.account.user) === null || _state$account$user5 === void 0 ? void 0 : _state$account$user5.avatar) || '';
  },
  hid: function hid(state) {
    var _state$account$user6;

    return ((_state$account$user6 = state.account.user) === null || _state$account$user6 === void 0 ? void 0 : _state$account$user6.hid) || '';
  },
  hpid: function hpid(state) {
    var _state$account$user7;

    return ((_state$account$user7 = state.account.user) === null || _state$account$user7 === void 0 ? void 0 : _state$account$user7.hpid) || '';
  },
  getTodoById: function getTodoById(state) {
    return function (id) {
      return state.account.todos.find(function (todo) {
        return todo.id === id;
      });
    };
  }
};
var store = createStore({
  modules: {
    account: account,
    app: app
  },
  getters: getters
});
export default store;