import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus';
import store from '@/store';
import { getToken, getChannelCode } from '@/utils/auth';
var loadingIns;
var messageIns; //正在请求的数量

var requestCount = 0; //显示loading

var openLoading = function openLoading() {
  if (requestCount === 0 && !loadingIns) {
    loadingIns = ElLoading.service({
      text: "努力加载中...",
      background: 'rgba(0, 0, 0, 0.6)',
      spinner: 'el-icon-loading',
      fullscreen: true
    });
  }

  requestCount++;
}; //隐藏loading


var hideLoading = function hideLoading() {
  requestCount--;

  if (requestCount < 1) {
    requestCount = 0;

    if (loadingIns) {
      loadingIns.close();
    }
  }
};

var relogin = function relogin(code, url) {
  var noneTokenCodes = [401, 50008, 50012, 50014];

  if (!noneTokenCodes.includes(code)) {
    return false;
  }

  if (url && url.indexOf('/logout') > 0) {
    return true;
  }

  ElMessageBox.confirm('会话已经过期，请重新登录', '系统提示', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(function () {
    store.dispatch('account/logout');
  });
  return true;
};

var instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // eg. '/v1'
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 45000 // request timeout
  // headers: { 'Content-Type': 'application/json;charset=UTF-8;' }

}); //请求拦截器

instance.interceptors.request.use(function (config) {
  // console.log('instance config', config)
  if (config.showLoading) {
    openLoading();
  }

  var token = getToken();

  if (token) {
    config.headers['token'] = token;
    config.headers['ly_token'] = token;
  }

  config.headers['channelCode'] = getChannelCode();
  config.headers['ly_biz'] = 'HOSPITAL';
  return config;
}, function (error) {
  Promise.reject(error);
  console.log('instance config error - ', error);
});
var ERROR_MAP = {
  400: '请求错误',
  401: '请求错误',
  404: '请求地址出错',
  408: '请求超时',
  500: '服务器内部错误!',
  501: '服务未实现!',
  502: '网关错误!',
  503: '服务不可用!',
  504: '网关超时!',
  505: 'HTTP版本不受支持'
};
instance.interceptors.response.use(function (response) {
  var _response$config;

  var _response$data = response.data,
      _response$data$code = _response$data.code,
      code = _response$data$code === void 0 ? 0 : _response$data$code,
      _response$data$messag = _response$data.message,
      message = _response$data$messag === void 0 ? '' : _response$data$messag;
  var responseUrl = response === null || response === void 0 ? void 0 : (_response$config = response.config) === null || _response$config === void 0 ? void 0 : _response$config.url;
  hideLoading(); // console.log('code -- ', response, code, message, responseUrl)

  if (code == 1) {
    return response.data;
  }

  messageIns && messageIns.close();
  messageIns = ElMessage.error({
    message: message || '出错了~',
    duration: 4000
  });

  if (relogin(code, responseUrl)) {
    return null;
  }

  return Promise.reject(new Error(message || 'Error'));
}, function (error) {
  var _error$response, _error$response2, _error$response2$conf;

  // console.log('instance.interceptors.response error', error, error.response)
  hideLoading();
  var errCode = (error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) || 0;
  var errUrl = error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : (_error$response2$conf = _error$response2.config) === null || _error$response2$conf === void 0 ? void 0 : _error$response2$conf.url; //响应错误

  if (errCode) {
    if (!relogin(errCode, errUrl)) {
      var message = ERROR_MAP[errCode] || '请求失败';
      messageIns && messageIns.close();
      messageIns = ElMessage.error({
        message: message,
        duration: 4000
      });
    }
  }

  return Promise.reject(error);
});
export default instance;