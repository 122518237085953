import { createVNode as _createVNode, Fragment as _Fragment } from "vue";
import { ref, h } from 'vue';
export default {
  name: 'MenuLabel',
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  render: function render() {
    //return [h('i', { class: this.icon ? 'iconfont icon-' + this.icon : 'icon-space' }), this.title ? h('span', {}, this.title) : null]
    var iconClz = this.icon ? 'iconfont icon-' + this.icon : 'icon-space';
    return _createVNode(_Fragment, null, [_createVNode("i", {
      "class": iconClz
    }, null), _createVNode("span", null, [this.title])]);
  },
  setup: function setup(props) {
    var _props$meta, _props$meta2;

    var icon = ref(((_props$meta = props.meta) === null || _props$meta === void 0 ? void 0 : _props$meta.icon) || '');
    var title = ref(((_props$meta2 = props.meta) === null || _props$meta2 === void 0 ? void 0 : _props$meta2.title) || '');
    return {
      icon: icon,
      title: title
    };
  }
};