import { computed } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: 'AppMain',
  setup: function setup() {
    var route = useRoute();
    console.log(route.meta, 'dddddd');
    var pathKey = computed(function () {
      return route.path;
    });
    var cachedViews = computed(function () {
      return []; // // this.$store.state.tagsView.cachedViews
    });
    return {
      pathKey: pathKey,
      cachedViews: cachedViews
    };
  }
};