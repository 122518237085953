import _objectSpread from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { ref } from 'vue';
import { AppMain, Navbar, Sidebar } from './components';
import ResizeMixin from '@/mixins/ResizeHandler';
import { mapGetters, useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default {
  name: 'Layout',
  mixins: [ResizeMixin],
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    Sidebar: Sidebar
  },
  setup: function setup() {
    var route = useRoute();
    var store = useStore();
    var pathKey = ref(route.path);
    var needTagsView = ref(false);
    var fixedHeader = ref(true);

    var handleClickOutside = function handleClickOutside() {
      // console.log('handleClickOutside -- ')
      store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }; // console.log('pathKey - ', pathKey.value)


    return {
      device: 'pc',
      pathKey: pathKey,
      fixedHeader: fixedHeader,
      needTagsView: needTagsView,
      handleClickOutside: handleClickOutside
    };
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'device']))
};