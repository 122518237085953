import { isExternal } from '@/utils/index';
import { ref, reactive } from 'vue';
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var linkProps = function linkProps(url) {
      if (isExternal(url)) {
        return {
          href: url,
          target: '_blank',
          rel: 'noopener'
        };
      }

      return {
        to: url
      };
    };

    var linkType = function linkType(url) {
      return isExternal(url) ? 'a' : 'router-link';
    };

    var info = reactive(linkProps(props.to));
    var ctype = ref(linkType(props.to));
    return {
      info: info,
      ctype: ctype
    };
  }
};