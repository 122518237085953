import request from '@/utils/request';
export function requestGet(url, params) {
  return request({
    url: url,
    method: 'GET',
    params: params
  });
}
export function requestPost(url, data) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return request({
    url: url,
    method: 'POST',
    data: data,
    params: params
  });
}
export function requestPut(url, data) {
  return request({
    url: url,
    method: 'PUT',
    data: data
  });
}
export function requestDelete(url, params) {
  return request({
    url: url,
    method: 'DELETE',
    params: params
  });
}