import { render } from "./Navbar.vue?vue&type=template&id=d16d6306&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=js"
export * from "./Navbar.vue?vue&type=script&lang=js"

import "./Navbar.vue?vue&type=style&index=0&id=d16d6306&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-d16d6306"
/* hot reload */
if (module.hot) {
  script.__hmrId = "d16d6306"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d16d6306', script)) {
    api.reload('d16d6306', script)
  }
  
  module.hot.accept("./Navbar.vue?vue&type=template&id=d16d6306&scoped=true", () => {
    api.rerender('d16d6306', render)
  })

}

script.__file = "src/layout/components/Navbar.vue"

export default script