import _objectSpread from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.filter.js";
// import path from 'path'
// const path = require('path')
import { isExternal } from '@/utils';
import MenuLabel from './Label.vue';
import AppLink from './Link.vue';
import { reactive } from 'vue';
export default {
  name: 'SidebarItem',
  components: {
    MenuLabel: MenuLabel,
    AppLink: AppLink
  },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props) {
    var onlyOneChild = reactive({
      isLink: false
    });

    var hasOneShowingChild = function hasOneShowingChild(parent) {
      if (parent.alwaysShow) {
        return false;
      }

      var children = (parent === null || parent === void 0 ? void 0 : parent.children) || [];
      var showingChildren = children.filter(function (p) {
        return !(p !== null && p !== void 0 && p.hidden || false);
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        onlyOneChild = _objectSpread(_objectSpread({}, showingChildren[0]), {}, {
          isLink: true
        });
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        onlyOneChild = _objectSpread(_objectSpread({}, parent), {}, {
          noShowingChildren: true,
          isLink: true
        });
        return true;
      }

      return false;
    };

    hasOneShowingChild(props.item); // console.log('onlyOneChild', onlyOneChild)

    return {
      onlyOneChild: onlyOneChild
    };
  },
  methods: {
    resolvePath: function resolvePath(routePath) {
      // console.log('routePath', routePath)
      if (isExternal(routePath)) {
        return routePath;
      }

      if (isExternal(this.basePath)) {
        return this.basePath;
      } // console.log('this.basePath, routePath', this.basePath, routePath)
      // let newPath = [this.basePath, routePath].join('/')
      // return newPath.replace('//', '/')


      return routePath; // return path.resolve(this.basePath, routePath)
    }
  }
};