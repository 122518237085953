import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

/**
 * 后台动态菜单 
 */
import Layout from '@/layout/index.vue';
export var asyncRoutes = [{
  path: "/",
  component: Layout,
  redirect: '/dashboard',
  hidden: true,
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@pro/views/index/dashboard.vue');
    },
    name: 'Dashboard',
    hidden: true,
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/usermanage',
  component: Layout,
  redirect: '/usermanage/userlist',
  name: 'UserList',
  alwaysShow: true,
  meta: {
    title: '用户管理',
    icon: 'misc-fill',
    affix: true
  },
  children: [{
    path: '/usermanage/userlist',
    component: function component() {
      return import('@pro/views/usermanage/userlist.vue');
    },
    name: 'UserList',
    meta: {
      title: '用户管理'
    }
  }, {
    path: '/usermanage/userdetail',
    component: function component() {
      return import('@pro/views/usermanage/userdetail.vue');
    },
    name: 'UserDetail',
    hidden: true,
    meta: {
      title: '用户详情'
    }
  }]
}, {
  path: '/meetingsmanage',
  component: Layout,
  redirect: '/meetingsmanage/meetinglist',
  name: 'MeetingList',
  alwaysShow: true,
  meta: {
    title: '会议管理',
    icon: 'misc-fill',
    affix: true
  },
  children: [{
    path: '/meetingsmanage/meetinglist',
    component: function component() {
      return import('@pro/views/meetingsmanage/meetinglist.vue');
    },
    name: 'MeetingList',
    meta: {
      title: '会议管理'
    }
  }, {
    path: '/meetingsmanage/editmeeting/:id?',
    component: function component() {
      return import('@pro/views/meetingsmanage/editmeeting.vue');
    },
    name: 'EditMeeting',
    hidden: true,
    meta: {
      title: '会议配置'
    }
  }, {
    path: '/meetingsmanage/meetingdetail/:id?',
    component: function component() {
      return import('@pro/views/meetingsmanage/meetingdetail.vue');
    },
    name: 'MeetingDetail',
    hidden: true,
    meta: {
      title: '会议详情'
    }
  }]
}, {
  path: '/studysmanage',
  component: Layout,
  redirect: '/studysmanage/studylist',
  name: 'StudyList',
  alwaysShow: true,
  meta: {
    title: '在线学习管理',
    icon: 'misc-fill',
    affix: true
  },
  children: [{
    path: '/studysmanage/studylist',
    component: function component() {
      return import('@pro/views/studysmanage/studylist.vue');
    },
    name: 'StudyList',
    meta: {
      title: '在线学习管理'
    }
  }, {
    path: '/studysmanage/editstudy/:id?',
    component: function component() {
      return import('@pro/views/studysmanage/editstudy.vue');
    },
    name: 'EditStudy',
    hidden: true,
    meta: {
      title: '学习配置'
    }
  }, {
    path: '/studysmanage/editcourse/:id?',
    component: function component() {
      return import('@pro/views/studysmanage/editcourse.vue');
    },
    name: 'EditCourse',
    hidden: true,
    meta: {
      title: '课程配置'
    }
  }]
}, {
  path: '/invoicesmanage',
  component: Layout,
  redirect: '/invoicesmanage/meetinglist',
  name: 'InvoiceList',
  alwaysShow: true,
  meta: {
    title: '发票管理',
    icon: 'misc-fill',
    affix: true
  },
  children: [{
    path: '/invoicesmanage/invoicelist',
    component: function component() {
      return import('@pro/views/invoicesmanage/invoicelist.vue');
    },
    name: 'InvoiceList',
    meta: {
      title: '发票管理'
    }
  }, {
    path: '/invoicesmanage/invoicedetail/:id?',
    component: function component() {
      return import('@pro/views/invoicesmanage/invoicedetail.vue');
    },
    name: 'InvoiceDetail',
    hidden: true,
    meta: {
      title: '发票详情'
    }
  }]
}, {
  path: '/speciality',
  component: Layout,
  redirect: '/speciality/list',
  name: 'specialityList',
  alwaysShow: true,
  meta: {
    title: '专委会管理',
    icon: 'misc-fill',
    affix: true
  },
  children: [{
    path: '/speciality/admin/adminList',
    component: function component() {
      return import('@pro/views/speciality/admin/adminList.vue');
    },
    name: 'specialityAdmin',
    meta: {
      title: '委员会管理'
    }
  }, {
    path: '/speciality/admin/detail',
    component: function component() {
      return import('@pro/views/speciality/admin/detail.vue');
    },
    name: 'specialityAdminDetail',
    hidden: true,
    meta: {
      title: '委员会管理详情编辑'
    }
  }, {
    path: '/speciality/list',
    component: function component() {
      return import('@pro/views/speciality/list.vue');
    },
    name: 'speciality',
    meta: {
      title: '专业管理'
    }
  }, {
    path: '/examine/list',
    component: function component() {
      return import('@pro/views/speciality/examine/list.vue');
    },
    name: 'examine',
    meta: {
      title: '初审列表'
    }
  }, {
    path: '/examine/detail',
    component: function component() {
      return import('@pro/views/speciality/examine/detail.vue');
    },
    name: 'examineDetail',
    hidden: true,
    meta: {
      title: '审核详情'
    }
  }, {
    path: '/candidate/list',
    component: function component() {
      return import('@pro/views/speciality/candidate/list.vue');
    },
    name: 'candidate',
    meta: {
      title: '候选人列表'
    }
  }, {
    path: '/member/list',
    component: function component() {
      return import('@pro/views/speciality/member/list.vue');
    },
    name: 'member',
    meta: {
      title: '委员管理'
    }
  }]
}, {
  path: "/admin",
  component: Layout,
  redirect: '/admin/staff',
  name: 'AdminMenu',
  alwaysShow: true,
  meta: {
    title: '权限管理',
    icon: 'user-group-fill',
    affix: true
  },
  children: [{
    path: '/admin/staff',
    component: function component() {
      return import('@pro/views/admin/staff');
    },
    name: 'AdminStaff',
    meta: {
      title: '后台用户',
      affix: true
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@com/views/errorPage/404.vue');
  },
  hidden: true,
  meta: {
    title: '404'
  }
}, {
  path: '/401',
  component: function component() {
    return import('@com/views/errorPage/401.vue');
  },
  hidden: true,
  meta: {
    title: '401'
  }
}];