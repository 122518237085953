import _objectSpread from "/Users/boji/Documents/leyue/association-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { mapGetters } from 'vuex'; // import { changePassword } from '@pro/api/user'

import Breadcrumb from '@/components/Breadcrumb/index.vue';
import ErrorLog from '@/components/ErrorLog/index.vue';
import { reactive, ref, computed } from 'vue';
import store from '@/store';
import Logo from './Sidebar/Logo';
import { useRouter } from 'vue-router'; // import Screenfull from '@/components/Screenfull'
// import SizeSelect from '@/components/SizeSelect'
// import LangSelect from '@/components/LangSelect'

export default {
  components: {
    Breadcrumb: Breadcrumb,
    ErrorLog: ErrorLog,
    Logo: Logo // Screenfull,
    // SizeSelect
    // LangSelect,
    // Search

  },
  setup: function setup() {
    var router = useRouter(); // const sidebarOpened = ref(true)

    var rightMenus = ref([{
      code: 'home',
      title: '首页',
      divided: false
    }, // { code: 'pwd', title: '修改密码', divided: false },
    {
      code: 'logout',
      title: '退出',
      divided: true
    }]);
    var dialogChangePasswordVisible = ref(false);
    var changePasswordData = reactive({
      oldPassword: '',
      newPassword: '',
      newPassword2: ''
    });

    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value !== changePasswordData.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      }

      callback();
    }; // const nickname = computed(() => localStorage.nickname)


    var nickname = localStorage.nickname;
    var passwordLengthRule = {
      min: 8,
      max: 24,
      message: '长度在8到24个字符',
      trigger: ['blur', 'change']
    };

    var toggleSideBar = function toggleSideBar() {
      store.dispatch('app/toggleSideBar'); // sidebarOpened.value = !sidebarOpened.value
    };

    function clickRightMenu(code) {
      switch (code) {
        case 'home':
          router.push({
            path: '/'
          });
          break;

        case 'pwd':
          dialogChangePasswordVisible.value = true;
          break;

        case 'logout':
          store.dispatch('account/logout');
          this.$router.push("/login?redirect=".concat(this.$route.fullPath));
          break;
      }
    }

    return {
      rightMenus: rightMenus,
      clickRightMenu: clickRightMenu,
      nickname: nickname,
      // sidebarOpened,
      changePasswordData: changePasswordData,
      dialogChangePasswordVisible: dialogChangePasswordVisible,
      toggleSideBar: toggleSideBar,
      changePasswordDataRules: {
        oldPassword: [{
          required: true,
          message: '原始密码不能为空',
          trigger: 'blur'
        }, passwordLengthRule],
        newPassword: [{
          required: true,
          message: '新密码不能为空',
          trigger: 'blur'
        }, passwordLengthRule],
        newPassword2: [{
          required: true,
          message: '请再输入一次新密码',
          trigger: 'blur'
        }, passwordLengthRule, {
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'avatar', 'name', 'device'])),
  methods: {
    savePassword: function savePassword(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {// this.$store.dispatch('user/changePassword', this.changePasswordData).then(response => {
          // changePassword(this.changePasswordData)
          //   .then(response => {
          //     window.console.log(response)
          //     this.$message({
          //       message: '密码修改成功',
          //       type: 'success'
          //     })
          //     this.dialogChangePasswordVisible = false
          //     setTimeout(() => {
          //       this.logout()
          //     }, 1800)
          //   })
          //   .catch(error => {
          //     window.console.log(error)
          //   })
        } else {
          _this.$message.error('密码修改失败');

          window.console.log('error submit!!');
          return false;
        }
      });
    }
  }
};