import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout/index.vue';
export var constantRoutes = [{
  path: "/login",
  component: function component() {
    return import("@pro/views/login/index.vue");
  },
  hidden: true,
  meta: {
    title: '登录'
  }
}, {
  path: "/",
  component: Layout,
  redirect: '/dashboard',
  hidden: true,
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@pro/views/index/dashboard.vue');
    },
    name: 'Dashboard',
    hidden: true,
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@com/views/errorPage/404.vue');
  },
  hidden: true,
  meta: {
    title: '404'
  }
}, {
  path: '/401',
  component: function component() {
    return import('@com/views/errorPage/401.vue');
  },
  hidden: true,
  meta: {
    title: '401'
  }
}];