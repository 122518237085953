import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_menu_label = _resolveComponent("menu-label");

  var _component_el_menu_item = _resolveComponent("el-menu-item");

  var _component_app_link = _resolveComponent("app-link");

  var _component_sidebar_item = _resolveComponent("sidebar-item", true);

  var _component_el_submenu = _resolveComponent("el-submenu");

  return !$props.item.hidden ? (_openBlock(), _createBlock("div", _hoisted_1, [$setup.onlyOneChild.isLink && $setup.onlyOneChild.meta ? (_openBlock(), _createBlock(_component_app_link, {
    key: 0,
    to: $options.resolvePath($setup.onlyOneChild.path)
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_menu_item, {
        index: $options.resolvePath($setup.onlyOneChild.path),
        class: "submenu-title-noDropdown"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_menu_label, {
            meta: $setup.onlyOneChild.meta
          }, null, 8
          /* PROPS */
          , ["meta"])];
        }),
        _: 1
        /* STABLE */

      }, 8
      /* PROPS */
      , ["index"])];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["to"])) : (_openBlock(), _createBlock(_component_el_submenu, {
    key: 1,
    ref: "subMenu",
    index: $options.resolvePath($props.item.path),
    "popper-append-to-body": ""
  }, {
    title: _withCtx(function () {
      return [_createVNode(_component_menu_label, {
        meta: $props.item.meta
      }, null, 8
      /* PROPS */
      , ["meta"])];
    }),
    default: _withCtx(function () {
      return [(_openBlock(true), _createBlock(_Fragment, null, _renderList($props.item.children, function (child) {
        return _openBlock(), _createBlock(_component_sidebar_item, {
          key: child.path,
          "is-nest": true,
          item: child,
          "base-path": $options.resolvePath(child.path),
          class: "nest-menu"
        }, null, 8
        /* PROPS */
        , ["item", "base-path"]);
      }), 128
      /* KEYED_FRAGMENT */
      ))];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["index"]))])) : _createCommentVNode("v-if", true);
}