import { inject } from 'vue';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup() {
    var title = inject('globalTitle');
    return {
      title: title
    };
  }
};